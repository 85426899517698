// src/components/About.js
import React from 'react';
import libraryImage from '../assets/images/library.jpg';

const About = () => (
  <div id="about" className="content">
    <h2>About</h2>
    <h3>Avid Fayaz
    </h3>
    <img src={libraryImage} alt="Library" />
    <p>
      I’m a research assistant and computer science student at KTH, Stockholm. Working with AI and languages since 2017. Currently, I am working to develop new AI-based models that can be used in automating parts of the work done by TA’s in programming courses.
    </p>
    <p>
      Since October 2024, I have been building Sibel, an AI powered intelligence terminal. You can find more on our work on <a href="https://sibel.se">sibel.se</a>  
    </p>
    <p>
      From spring of 2023 until summer of 2024, I was the head of the education team at the KTH AI Society. Where I am currently a board advisor. During my time as head of education, I was working on projects concerning medical and educational applications of AI.
    </p>
    <p>
      Beyond my work, I have an interest for economics, physics and astrophysics, philosphy and classical music. 
    </p>
  </div>
);

export default About;
